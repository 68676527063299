'use client';
import { useRouter } from 'next/navigation';

import { useState } from 'react';

import { Alert, Button, Stack, TextInput } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { IconInfoCircle } from '@tabler/icons-react';

import { z } from 'zod';

import { PAGES } from '@/shared/constants';

export const LoginForm = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const schema = z.object({
    login: z.string().min(1, { message: 'Login should be provided' }),
    password: z.string().min(1, { message: 'Password should be provided' }),
  });

  const mform = useForm({
    initialValues: { login: '', password: '' },
    validate: zodResolver(schema),
  });

  const handleSubmit = async (values: typeof mform.values) => {
    setError(false);
    setIsLoading(true);
    if (
      values.login === atob('VGVzdCBBY2NvdW50') &&
      values.password === atob('UGVyZmVjdFBhc3N3b3JkMTIzIQ==')
    ) {
      fetch('/api/auth/login/', {
        method: 'POST',
      })
        .then(() => {
          router.push(`${PAGES.PROJECTS}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <form noValidate onSubmit={mform.onSubmit(handleSubmit)}>
      <Stack gap={15}>
        <TextInput label="Login" {...mform.getInputProps('login')} />

        <TextInput
          label="Password"
          type="password"
          {...mform.getInputProps('password')}
        />

        {error && (
          <Alert
            color="red"
            icon={<IconInfoCircle />}
            mb={26}
            title={`Incorrect login or password`}
            variant="outline"
          >
            Please make sure that you are using correct login and password
          </Alert>
        )}

        <Button fullWidth loading={isLoading} type="submit" variant="filled">
          Login
        </Button>
      </Stack>
    </form>
  );
};
